<template>
  <div class="week-rate" v-if="$route.params.id && show">
    <div class="row infor-row">
      <div class="row-left contect">
        <p class="row table-row"><span>WEEK</span><span>RATE</span></p>
        <el-divider></el-divider>
        <div v-for="(item, index) in hashDateSmall" :key="index">
          <p class="row table-row">
            <span >{{ item.from_date | dateFormat("MM/DD") }} - {{ item.to_date | dateFormat("MM/DD/YYYY") }}
            </span>
            <span v-if="item.flag == 'true'" style="color: green">{{ item.amount | currencyPrice("$ ") }}</span>
            <span v-else-if="item.flag == 'half_false'" style="color: orange">{{  item.amount | currencyPrice("$ ") }}</span>
            <span v-else-if="item.flag == 'false'"><s>{{ item.amount | currencyPrice("$ ") }}</s></span>
          </p>
          <el-divider></el-divider>
        </div>
      </div>
      <div class="row-right contect">
        <div class="pc">
          <p class="row table-row pc"><span>WEEK</span><span>RATE</span></p>
          <el-divider class="pc"></el-divider>
        </div>
        <div v-for="(item, index) in hashDateBig" :key="index">
          <p class="row table-row">
            <span>{{ item.from_date | dateFormat("MM/DD") }} - {{ item.to_date | dateFormat("MM/DD/YYYY") }}</span>
            <span v-if="item.flag == 'true'" style="color: green">{{ item.amount | currencyPrice("$ ") }}</span>
            <span v-else-if="item.flag == 'half_false'" style="color: orange">{{ item.amount | currencyPrice("$ ") }}</span>
            <span v-else-if="item.flag == 'false'"> <s>{{ item.amount | currencyPrice("$") }}</s></span>
          </p>
          <el-divider></el-divider>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FullCalendar } from "vue-full-calendar";
import dayjs from "dayjs";
export default {
  props: ["data"],
  data() {
    return {
      date: "",
      ratesList: [], // 存放价格列表
      config: {
        defaultDate: this.defaultDate,
      },
      show: true,
      day: null,
    };
  },
  components: { FullCalendar },
  computed: {
    defaultDate() {
      return dayjs(this.date).format("YYYY-MM");
    },
    hashDate() {
      let hash = [];
      let availabilities = [];
      if(this.ratesList.length!=0){
         if(dayjs(this.ratesList[0].from_date).format("MM")>=dayjs(this.date).format("MM")){
           let obj={
            from_date: dayjs(this.ratesList[0].from_date).subtract(7, "day").format("YYYY-MM-DD"),
            to_date: this.ratesList[0].from_date,
            amount: "",
            flag: "true",}
           hash.push(obj)
        }
      for (let i = 0; i < this.ratesList.length; i++) {
        if (dayjs(this.ratesList[i].to_date).format("MM") >= 5 && dayjs(this.ratesList[i].from_date).subtract(this.day, 'day').format("MM")<10&&dayjs(this.ratesList[i].from_date).format("MM") <= 10 &&  hash.indexOf(this.ratesList[i].from_date) == -1 ) {
          hash.push(this.ratesList[i]);
        }
      }
      if (this.data.availabilities) {
        let arr1 = [];
        this.data.availabilities.forEach((item) => {
          if ( dayjs(item.from_date)>(dayjs(hash[0].from_date))) {
            arr1.push(item);
          }
        });
        for (let i = 0; arr1.length > i; i++) {
          //去除不属于不属于表格的禁用日期
          if (i === arr1.length-1) {
            availabilities.push(arr1[i]);
          } else {
            if (arr1[i].to_date == arr1[i + 1].from_date) {
              let obj = {
                from_date: arr1[i].from_date,
                to_date: arr1[i + 1].to_date,
              };
              availabilities.push(obj);
              arr1.splice(i + 1, 1);
            } else {
              availabilities.push(arr1[i]);
            }
          }
        } 
        if (availabilities.length != 0 && hash.length != 0) {
          //判断价格属于禁用，半禁用，未禁用
          for (let i = 0; hash.length > i; i++) {
            for (let j = 0; availabilities.length > j; j++) {
              if (
                dayjs(hash[i].from_date) >= dayjs(availabilities[j].from_date) &&
                dayjs(availabilities[j].to_date) > dayjs(hash[i].from_date) &&
                dayjs(hash[i].to_date) > dayjs(availabilities[j].from_date) &&
                dayjs(availabilities[j].to_date) >= dayjs(hash[i].to_date)
              ) {
                let aa = hash[i];
                aa.flag = "false";
                this.$set(hash, i, aa);
                break;
              } else if (
                (dayjs(hash[i].from_date) <= dayjs(availabilities[j].from_date) && dayjs(hash[i].from_date) <= dayjs(availabilities[j].to_date) ||
                dayjs(hash[i].from_date) >= dayjs(availabilities[j].from_date) && dayjs(availabilities[j].to_date) >= dayjs(hash[i].to_date) ||
                dayjs(hash[i].from_date) >= dayjs(availabilities[j].from_date) && dayjs(hash[i].to_date) >= dayjs(availabilities[j].to_date))
                &&(dayjs(availabilities[j].to_date) > dayjs(hash[i].from_date) && dayjs(hash[i].to_date) > dayjs(availabilities[j].from_date))
              ) {
                let aa = hash[i];
                aa.flag = "half_false";
                this.$set(hash, i, aa);
                break;
              } else {
                let aa = hash[i];
                aa.flag = "true";
                this.$set(hash, i, aa);
              }
            }
          }
        }
        if (this.data.rates.length != 0 && hash.length != 0) {
          this.data.rates.forEach((item) => {
            //填充价格
            hash.forEach((n) => {
              if ( dayjs(n.from_date).format("YYYY-MM-DD") ===  dayjs(item.from_date).add(this.day,"day").format("YYYY-MM-DD")) {
                n.amount = item.amount;
              }
            });
          });
          if ( dayjs( this.data.rates[this.data.rates.length - 1].from_date )>(dayjs(hash[0].from_date)) ) {
            //判断是否显示表格
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          this.show = false;
        }

        // 去重
        let obj = {};
        let peon = hash.reduce((cur, next) => {
          obj[next.from_date]
            ? ""
            : (obj[next.from_date] = true && cur.push(next));
          return cur;
        }, []); //设置cur默认类型为数组，并且初始值为空的数组
        return peon;
      }}
    },
    hashDateSmall() {
      let hash = [];
      if (this.hashDate) {
        this.hashDate.forEach((item) => {
          if (dayjs(item.from_date).format("MM") <= 7) {
            hash.push(item);
          }
        });

        return hash;
      }
    },
    hashDateBig() {
      let hash = [];
      if (this.hashDate && this.hashDate != undefined) {
        this.hashDate.forEach((item) => {
          if (dayjs(item.from_date).format("MM") > 7) {
            hash.push(item);
          }
        });
        return hash;
      }
    },
  },
  watch: {
    defaultDate() {
      this.setRatesList();
    },
  },
  methods: {
    // 设置当月每周的价格
    setRatesList() {
       if( this.data&&this.data.requirement){
      if(this.data.requirement.turnover_day === "Saturday" ){
         this.day = 6;
      }else if( this.data.requirement.turnover_day === "Friday" ){
         this.day = 5;
      }else if( this.data.requirement.turnover_day === "Thursday" ){
         this.day = 4;
      }else if( this.data.requirement.turnover_day === "Wednesday" ){
         this.day = 3;
      }else if( this.data.requirement.turnover_day === "Tuesday" ){
         this.day = 2;
      }else if( this.data.requirement.turnover_day === "Monday" ){
         this.day = 1;
      }else {
         this.day = 0;
      }}
      this.ratesList.length = 0; // 清空之前的数据
      let arr = [];
      for (let i = 0; i < 4; i++) {
        arr.push(dayjs(this.defaultDate).add(i, "month").format("YYYY-MM-DD"));
      }
      arr.forEach((item) => {
        let firstSunday = dayjs(item).set("day", this.day); // 该月份第一个星期的星期天
        for (let i = 0; i <= 6; i++) {
          let key = firstSunday.add(i * 7, "day").format("YYYY-MM-DD");
          this.ratesList.push({
            from_date: key,
            to_date: dayjs(key).add(7, "day").format("YYYY-MM-DD"),
            amount: "",
            flag: "true",
          });
        }
      });
    },
  },
  created() {
      let myDate = new Date();
      let tYear = myDate.getFullYear();
      let tMonth = myDate.getMonth() + 1;
      if (tMonth > 6) {
        this.date = tYear + 1 + "/6"+"/01";
      } else {
        this.date = tYear + "/6"+"/01";
      }
  },
};
</script>
<style lang="scss" scoped>
$width: calc(calc(100% - 2px) / 2);
.week-rate {
  margin-left: 2px;
  .infor-row {
    justify-content: space-between;
    align-items: flex-start;
    .contect {
      width: $width;
      /deep/ .el-divider--horizontal {
        margin: 10px 0;
      }
    }
    .table-row {
      justify-content: space-between;
      padding-left: 10px;
      // margin: 0;
      span {
        color: #38425b;
        font-family: "Roboto-Regular";
        text-align: left;
        display: inline-block;
        width: $width;
      }
    }
  }
  @media (max-width:992px) {
    .infor-row{
      display: block;
      .contect{width: 100%;}
    }
    .pc{display: none; /deep/ .el-divider--horizontal {
        display: none;
      }}
  }
  
}
</style>